<script setup>
import { storeToRefs } from "pinia";
import { useLayout2WeeklyAssignedContentsStore } from "../../stores/useLayout2WeeklyAssignedContents.store";
import Layout2ContentsList from "../contents/Layout2ContentsList.vue";
import DeferredContent from "@/components/utilities/DeferredContent.vue";
import { useLayout2HomeFiltersStore } from "../../stores/useLayout2HomeFilters.store";

const layout2FiltersStore = useLayout2HomeFiltersStore()
const layout2WeeklyAssignedContentStore =
  useLayout2WeeklyAssignedContentsStore();

const { currentWeekAssigned, loadingWeeklyAssignedContentsCurrentWeek } =
  storeToRefs(layout2WeeklyAssignedContentStore);
  const  { selectedFilter } = storeToRefs(layout2FiltersStore)

const handleFetchThisWeekAssignedContents = async () => {
  await layout2WeeklyAssignedContentStore.handleFetchCurrentWeekAssignedContents();
  layout2FiltersStore.handleFilterBySort(selectedFilter.value.sort === 'a-z')
  layout2FiltersStore.handleFilterByProgress(selectedFilter.value.filter)
  layout2FiltersStore.handleFilterByContentType()
};
</script>
<template>
  <DeferredContent @onLoadComponent="handleFetchThisWeekAssignedContents">
    <Layout2ContentsList
      title="This Week's Assignments"
      :contents="currentWeekAssigned"
      :loading="loadingWeeklyAssignedContentsCurrentWeek"
      emptyText="No Assignments This Week"
    />
  </DeferredContent>
</template>

<style lang="scss" scoped></style>
