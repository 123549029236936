import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { AxiosError } from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useLayout2OverdueAssignmentsStore = defineStore("layout2OverdueAssignmentsStore", () => {

  const overdueAssignments = ref([]);
  const loadingOverdueAssignments = ref(false);
  const originalOverdueAssignments = ref([]);

  const handleFetchOverdueAssignmentsContents = async () => {
    try {
      loadingOverdueAssignments.value = true;
      const { data } = await http().get("/v3/overdue-assigned-contents");
      overdueAssignments.value = data.data;
      originalOverdueAssignments.value = [...data.data];
    } catch (error) {
      if (error instanceof AxiosError) {
        useHandleErrorStatus(error);
      }
    } finally {
      loadingOverdueAssignments.value = false;
    }
  }

  return {
    overdueAssignments,
    loadingOverdueAssignments,
    originalOverdueAssignments,
    handleFetchOverdueAssignmentsContents,
  }
})
