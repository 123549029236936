<template>
  <ElPopover
    :width="350"
    popper-style="padding-right: 0 !important; padding-left: 0 !important"
    trigger="click"
    :visible="visible"
  >
    <template #reference>
      <ElButton @click="handleClickVisible" size="large" :icon="FilterIcon">
        <!-- <FilterIcon class="w-4 text-untitled-gray-500" /> -->
        <span>Filter By Content Type</span>
      </ElButton>
    </template>
    <template #default>
      <div class="" ref="filterElement">
        <div class="mb-5 border-b border-gray-300 px-4 pb-2">
          <label class="text-sm font-semibold text-gray-900 pb-2"
            >Filter By Timeline</label
          >
          <fieldset class="mt-2">
            <div class="space-y-4">
              <GroupRadioBox
                v-model="timelineValue"
                :options="timeline"
                name="sort"
                value-key="value"
                label-key="label"
              />
            </div>
          </fieldset>
        </div>
        <div class="mb-2 px-4 pb-2">
          <label class="text-sm font-semibold text-gray-900 pb-2"
            >Filter By Content Type</label
          >
          <fieldset class="mt-2">
            <div class="space-y-4">
              <GroupRadioBox
                v-model="contentTypeValue"
                :options="contentTypes"
                name="filter"
                value-key="value"
                label-key="label"
              />
            </div>
          </fieldset>
        </div>
        <div class="w-full flex py-2 gap-x-2 px-4">
          <button
            type="button"
            class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm border border-gray-200 hover:bg-gray-20 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
            @click="visible = !visible"
          >
            Close
          </button>
          <div class="grow" />
          <button
            type="button"
            @click="handleClickClearFilter"
            class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm border border-gray-200 hover:bg-gray-20 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
          >
            Clear
          </button>
          <button
            type="button"
            @click="handleClickApplyFilter"
            class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm border border-gray-200 hover:bg-gray-20 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
          >
            Apply
          </button>
        </div>
      </div>
    </template>
  </ElPopover>
</template>

<script setup>
import { ElButton, ElPopover } from "element-plus";
import { useLayout2FilterByContentTypeStore } from "../../stores/useLayout2FilterByContentType.store";
import { storeToRefs } from "pinia";
import GroupRadioBox from "@/components/utilities/GroupRadioBox.vue";
import { defineProps, defineEmits, watch } from "vue";
import { computed } from "vue";
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { FilterIcon } from "@heroicons/vue/solid";

const props = defineProps({
  selectedTimeline: {
    type: String,
    default: null,
  },
  selectedFilterByContentType: {
    type: String,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "update:selectedFilterByContentType",
  "update:selectedTimeline",
  "clearFilters",
  "resetFilters",
  "applyFilters",
]);

const layout2FilterByContentTypeStore = useLayout2FilterByContentTypeStore();
const { contentTypes, timeline } = storeToRefs(layout2FilterByContentTypeStore);

const visible = ref(false);
const filterElement = ref(null);
const timelineValue = ref(null);
const contentTypeValue = ref(null);

const open = computed({
  get() {
    return props.visible;
  },
  set(newValue) {
    visible.value = newValue;
    emit("update:visible", newValue);
  },
});

const selectedFilterByContentType = computed({
  get() {
    return props.selectedFilterByContentType;
  },
  set(newValue) {
    emit("update:selectedFilterByContentType", newValue);
  },
});

const selectedTimeline = computed({
  get() {
    return props.selectedTimeline;
  },
  set(newValue) {
    emit("update:selectedTimeline", newValue);
  },
});

onClickOutside(filterElement, () => {
  visible.value = false;
});

const handleClickApplyFilter = () => {
  selectedFilterByContentType.value = contentTypeValue.value;
  selectedTimeline.value = timelineValue.value;
  visible.value = !visible.value;
  emit("applyFilters");
};

const handleClickClearFilter = () => {
  timelineValue.value = null;
  contentTypeValue.value = null;
  selectedFilterByContentType.value = null;
  selectedTimeline.value = null;
  emit("clearFilters");
};

const handleClickVisible = () => {
  visible.value = !visible.value;
};

watch(visible, (value) => {
  open.value = value;
});
</script>

<style lang="scss" scoped></style>
