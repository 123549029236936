<script setup>
import { storeToRefs } from 'pinia';
import { useLayout2OverdueAssignmentsStore } from '../../stores/useLayout2OverdueAssignments.store';
import Layout2ContentsList from '../contents/Layout2ContentsList.vue';
import DeferredContent from '@/components/utilities/DeferredContent.vue';
import { useLayout2HomeFiltersStore } from '../../stores/useLayout2HomeFilters.store';

const layout2FiltersStore = useLayout2HomeFiltersStore()
const layout2OverdueAssignments = useLayout2OverdueAssignmentsStore()
const { loadingOverdueAssignments, overdueAssignments, } = storeToRefs(layout2OverdueAssignments)
const  { selectedFilter } = storeToRefs(layout2FiltersStore)

const handleFetchOverdueAssignments = async () => {
  await layout2OverdueAssignments.handleFetchOverdueAssignmentsContents()
  layout2FiltersStore.handleFilterBySort(selectedFilter.value.sort === 'a-z')
  layout2FiltersStore.handleFilterByProgress(selectedFilter.value.filter)
  layout2FiltersStore.handleFilterByContentType()
}
</script>
<template>
  <DeferredContent @onLoadComponent="handleFetchOverdueAssignments">
    <Layout2ContentsList
      title="Overdue Assignments"
      :contents="overdueAssignments"
      :loading="loadingOverdueAssignments"
      emptyText="No Overdue Assignments"
    />
  </DeferredContent>
</template>

<style lang="scss" scoped></style>
