<template>
  <div
    class="w-full flex flex-col xl:flex-row gap-2 bg-untitled-gray-50 max-w-[2300px] mx-auto"
  >
    <div
      class="flex-1 flex flex-col gap-2 lg:border-r border-untitled-gray-300 p-2 sm:p-4 sm:gap-3 md:gap-4 lg:gap-5 w-full xl:w-3/4"
    >
      <Layout2Filters />
      <div class="">
        <Layout2MainDisplay />
      </div>
      <Layout2HomeMainTabs />
      <div class="w-full">
        <KeepAlive>
          <component :is="display" />
        </KeepAlive>
      </div>
    </div>
    <div class="w-full xl:w-1/4 flex flex-col gap-2 p-2 sm:p-4">
      <Layout2RightSection />
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, onBeforeMount } from "vue";
import Layout2HomeMainTabs from "./components/Layout2HomeMainTabs.vue";
import Layout2RightSection from "./components/Layout2RightSection.vue";
import { useLayout2HomeTabsStore } from "./stores/useLayout2HomeTabs.store";
import { storeToRefs } from "pinia";
import { useAllAvailableCourseStore } from "@/stores/useAllAvailableCourse.store";
import Layout2Filters from "./components/partials/Layout2Filters.vue";
import Layout2MainDisplay from "./components/Layout2MainDisplay.vue";

const Layout2FeatureMain = defineAsyncComponent(() =>
  import("./components/featured/Layout2FeatureMain.vue")
);
const Layout2CoursesMain = defineAsyncComponent(() =>
  import("./components/courses/Layout2CoursesMain.vue")
);
const Layout2PlaylistMain = defineAsyncComponent(() =>
  import("./components/playlists/Layout2PlaylistMain.vue")
);
const Layout2AvailableContentMain = defineAsyncComponent(() =>
  import("./components/contents/Layout2AvailableContentMain.vue")
);

const allAvailableCourseStore = useAllAvailableCourseStore();
const layout2HomeMainTabsStore = useLayout2HomeTabsStore();
const { selectedMainTab } = storeToRefs(layout2HomeMainTabsStore);

const display = computed(() => {
  if (selectedMainTab.value === "enrolled") {
    return Layout2CoursesMain;
  }
  if (selectedMainTab.value === "playlist") {
    return Layout2PlaylistMain;
  }
  if (selectedMainTab.value === "content") {
    return Layout2AvailableContentMain;
  }
  return Layout2FeatureMain;
});

onBeforeMount(() => {
  allAvailableCourseStore.handleFetchCategoriesWithCourses();
});
</script>

<style lang="scss" scoped></style>
