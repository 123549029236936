<script setup>
import GroupRadioBox from "@/components/utilities/GroupRadioBox.vue";
import { FilterIcon } from "@heroicons/vue/solid";
import { ElButton, ElPopover } from "element-plus";
import {
  defineProps,
  computed,
  defineEmits,
  onMounted,
  ref,
  watch,
  onBeforeUnmount,
} from "vue";
import CourseCategories from "@/components/utilities/CourseCategories.vue";
import { useLmsSettingsStore } from "@/stores/useLmsSettingsStore";
import { useEnrolledCourseStore } from "@/stores/enrolledCourseStore";
import { onClickOutside } from "@vueuse/core";
import { useAuthStore } from "@/stores";
import { getRoles } from "@/composable/users/getRoles";

const roles = getRoles();

const authStore = useAuthStore();
const authUserProfile = computed(() => authStore.user);
const props = defineProps({
  modelValue: {
    type: [Object, null],
    default: () => {},
  },
  visible: {
    type: Boolean,
    default: false,
  },
  hideSortProgress: {
    type: Boolean,
    default: false,
  },
  hideEnrolledCourse: {
    type: Boolean,
    default: false,
  },
  hideAvailableCourse: {
    type: Boolean,
    default: false,
  },
  showHighLowProgress: {
    type: Boolean,
    default: true,
  },
  showCategory: {
    type: Boolean,
    default: true,
  },
  custom: {
    type: Boolean,
    default: false,
  },
  filteredOptions: {
    type: Boolean,
    default: false,
  },
  excludedFilters: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits([
  "update:modelValue",
  "clearFilters",
  "resetFilters",
  "applyFilters",
  "update:visible",
]);
const lmsSettingsStore = useLmsSettingsStore();
const enrollCourseStore = useEnrolledCourseStore();
const hideEnrolledCourse = computed(() => props.hideEnrolledCourse);
const hideAvailableCourse = computed(() => props.hideAvailableCourse);
const showHighLowProgress = computed(() => props.showHighLowProgress);
const showCategory = computed(() => props.showCategory);
const filteredOptions = computed(() => props.filteredOptions);
const excludedFilters = computed(() => props.excludedFilters);
const appliedFilters = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});
const tabLabel = computed({
  get() {
    return enrollCourseStore.label;
  },
  set(newValue) {
    enrollCourseStore.label = newValue;
  },
});
const filters = ref([
  {
    name: "All",
    value: "all",
  },
  {
    name: "Not Started",
    value: "not started",
  },
  {
    name: "In Progress",
    value: "in progress",
  },
  {
    name: "Completed",
    value: "completed",
  },
]);

const newFilters = computed(() => {
  if (!filteredOptions.value) {
    return filters.value;
  }

  return filters.value.filter(
    (filter) => !excludedFilters.value.includes(filter)
  );
});

const sorts = ref([
  {
    name: "A-Z",
    value: "a-z",
  },
  {
    name: "Z-A",
    value: "z-a",
  },
  {
    name: "High to Low Progress",
    value: "htl",
  },
  {
    name: "Low to High Progress",
    value: "lth",
  },
]);

const filteredSorts = computed(
  () =>
  sorts.value.filter((sort) => {
    if (
      !showHighLowProgress.value &&
      (sort.value === "htl" || sort.value === "lth")
    ) {
      return false;
    }
    return true;
  })
);

const refreshKey = ref(0);
const selectedFilter = ref({
  sort: null,
  filter: null,
  available_course: false,
  enrolled_course: false,
  course_category: [],
});

const visible = ref(false);
const custom = computed(() => props.custom);
const open = computed({
  get() {
    return props.visible;
  },
  set(newValue) {
    visible.value = newValue;
    emit("update:visible", newValue);
  },
});
const filterElement = ref(null);

onClickOutside(filterElement, () => {
  if (enrollCourseStore.selectedFilter && !custom.value) {
    selectedFilter.value = structuredClone(enrollCourseStore.selectedFilter);
  } else {
    selectedFilter.value = structuredClone(appliedFilters.value);
  }

  visible.value = false;
});
const handleClickClearFilter = () => {
  appliedFilters.value = {
    sort: null,
    filter: null,
    available_course: false,
    enrolled_course: false,
    course_category: [],
  };
  selectedFilter.value = {
    sort: null,
    filter: null,
    available_course: false,
    enrolled_course: false,
    course_category: [],
  };
  emit("clearFilters");
  handleUpdateHomeTabLabel();
};

const handleClickApplyFilter = () => {
  appliedFilters.value = selectedFilter.value;
  visible.value = !visible.value;
  if (!custom.value) {
    handleUpdateHomeTabLabel();
    enrollCourseStore.selectedFilter = structuredClone(selectedFilter.value);
  }
  emit("applyFilters", appliedFilters.value);
};

const handleUpdateHomeTabLabel = () => {
  const isNew = authUserProfile.value.is_new;

  if (isNew && lmsSettingsStore.hasEnrollment) {
    tabLabel.value = "Available Courses";

    return;
  }

  if (
    lmsSettingsStore.hasEnrollment &&
    selectedFilter.value.available_course &&
    !selectedFilter.value.enrolled_course
  ) {
    tabLabel.value = "Available Courses";
    return;
  }

  if (
    lmsSettingsStore.hasEnrollment &&
    selectedFilter.value.enrolled_course &&
    !selectedFilter.value.available_course
  ) {
    tabLabel.value = "Enrolled Courses";
    return;
  }

  tabLabel.value = "All Courses";
};

const handleClickVisible = () => {
  visible.value = true;
};

const handleChangeCategories = () => {
  visible.value = true;
};

onMounted(async () => {
  if (enrollCourseStore.selectedFilter && !custom.value) {
    selectedFilter.value = structuredClone(enrollCourseStore.selectedFilter);
    appliedFilters.value = structuredClone(selectedFilter.value);
  }
  if (!custom.value) {
    enrollCourseStore.initializeFilters();
    handleUpdateHomeTabLabel();
  }
});

watch(appliedFilters, (newValue, oldValue) => {
  if (oldValue !== newValue) {
    selectedFilter.value = structuredClone(newValue);
    refreshKey.value++;
  }
});

watch(visible, (value) => {
  open.value = value;
});

onBeforeUnmount(() => {
  handleClickClearFilter();
  refreshKey.value = 0;
});

const isManager = computed(() => {
  return (
    roles.isSuperAdmin.value ||
    roles.isAccountManager.value ||
    roles.isSpecificManager.value
  );
});
</script>
<template>
  <el-popover
    :width="350"
    popper-style="padding-right: 0 !important; padding-left: 0 !important"
    trigger="click"
    :visible="visible"
  >
    <template #reference>
      <ElButton @click="handleClickVisible" size="large" :icon="FilterIcon">
        <!-- <FilterIcon class="w-4 text-untitled-gray-500" /> -->
        <span>Filters</span>
      </ElButton>
    </template>
    <template #default>
      <div class="" ref="filterElement">
        <div class="mb-5 border-b border-gray-300 px-4 pb-2">
          <label class="text-sm font-semibold text-gray-900 pb-2"
            >Sort By</label
          >
          <fieldset class="mt-2">
            <legend class="sr-only">Sort method</legend>
            <div class="space-y-4">
              <GroupRadioBox
                v-model="selectedFilter.sort"
                :options="filteredSorts"
                name="sort"
                value-key="value"
                label-key="name"
              />
            </div>
          </fieldset>
        </div>
        <div class="mb-2 px-4 pb-2">
          <label class="text-sm font-semibold text-gray-900 pb-2"
            >Filter By</label
          >
          <fieldset class="mt-2">
            <legend class="sr-only">Filters method</legend>
            <div class="space-y-4">
              <GroupRadioBox
                v-model="selectedFilter.filter"
                :options="newFilters"
                name="filter"
                value-key="value"
                label-key="name"
              />
            </div>
          </fieldset>
        </div>
        <div
          v-if="!isManager || hideEnrolledCourse"
          class="px-4 pb-2 mb-2"
          v-has-enrollment
        >
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input
                id="enrolled-course"
                v-model="selectedFilter.enrolled_course"
                aria-describedby="enrolled-course-description"
                name="enrolled-course"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="enrolled-course" class="font-medium text-gray-900"
                >Show Enrolled Courses</label
              >
            </div>
          </div>
        </div>
        <div
          v-if="!isManager || hideAvailableCourse"
          class="mb-2 px-4 pb-2"
          v-has-enrollment
        >
          <div class="relative flex items-start">
            <div class="flex h-6 items-center">
              <input
                id="available-course"
                v-model="selectedFilter.available_course"
                aria-describedby="available-course-description"
                name="available-course"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div class="ml-3 text-sm leading-6">
              <label for="available-course" class="font-medium text-gray-900"
                >Show Available Courses</label
              >
            </div>
          </div>
        </div>
        <div class="mb-5 px-4 pb-4 w-full" v-if="showCategory">
          <label for="comments" class="font-medium text-gray-900"
            >Course Categories</label
          >
          <CourseCategories
            v-model="selectedFilter.course_category"
            class="mt-2"
            :teleported="false"
            :key="refreshKey"
            :reload-per-select="true"
            @change="handleChangeCategories"
          />
        </div>
        <div class="w-full flex py-2 gap-x-2 px-4">
          <button
            type="button"
            class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm border border-gray-200 hover:bg-gray-20 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
            @click="visible = !visible"
          >
            Close
          </button>
          <div class="grow" />
          <button
            type="button"
            @click="handleClickClearFilter"
            class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm border border-gray-200 hover:bg-gray-20 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
          >
            Clear
          </button>
          <button
            type="button"
            @click="handleClickApplyFilter"
            class="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm border border-gray-200 hover:bg-gray-20 focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-gray-200"
          >
            Apply
          </button>
        </div>
      </div>
    </template>
  </el-popover>
</template>
