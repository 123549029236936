<script setup>
import { defineProps, computed, defineEmits } from "vue";

const props = defineProps({
  modelValue: null,
  options: {
    type: Array,
    default: () => [],
  },
  valueKey: {
    type: String,
    default: "value",
  },
  labelKey: {
    type: String,
    default: "name",
  },
  name: {
    type: String,
    default: "radio",
  },
});

const emit = defineEmits(["update:modelValue"]);

const selectedValue = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});
const options = computed(() => props.options);
const valueKey = computed(() => props.valueKey);
const labelKey = computed(() => props.labelKey);
const name = computed(() => props.name);
</script>
<template>
  <div class="space-y-4">
    <div
      v-for="(option, index) in options"
      :key="option.id"
      class="flex items-center"
      v-bind="$attrs"
    >
      <input
        v-model="selectedValue"
        :value="option[valueKey]"
        :id="index"
        :name="`${name}-method-${index}`"
        type="radio"
        class="border-gray-300 text-indigo-600 focus:ring-indigo-600"
      />
      <label
        :for="`${name}-method-${index}`"
        class="ml-3 block text-sm text-gray-900"
        >{{ option[labelKey] }}</label
      >
    </div>
  </div>
</template>
