import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useLayout2NewReleasesStore } from "./useLayout2NewReleases.store";
import { useLayout2WeeklyAssignedContentsStore } from "./useLayout2WeeklyAssignedContents.store";
import { useLayout2OverdueAssignmentsStore } from "./useLayout2OverdueAssignments.store";

export const useLayout2HomeFiltersStore = defineStore("layout2HomeFiltersStore", () => {

  const newReleaseStore = useLayout2NewReleasesStore();
  const weeklyAssignedContentsStore = useLayout2WeeklyAssignedContentsStore();
  const overdueAssignmentsStore = useLayout2OverdueAssignmentsStore();

  const { newReleases, originalNewRelease } = storeToRefs(newReleaseStore)
  const { currentWeekAssigned, lastWeekAssigned, originalCurrentWeekAssigned, originalLastWeekAssigned } = storeToRefs(weeklyAssignedContentsStore)
  const { overdueAssignments, originalOverdueAssignments } = storeToRefs(overdueAssignmentsStore)

  const selectedFilter = ref({
    available_course: false,
    sort: null,
    filter: null,
    course_category: [],
    enrolled_course: true,
  });
  const selectedTimeline = ref("")
  const selectedContentType = ref("");

  const handleReset = () => {
    selectedFilter.value = {
      available_course: false,
      sort: null,
      filter: null,
      course_category: [],
      enrolled_course: true,
    };
    selectedTimeline.value = null;
    selectedContentType.value = null;
  }

  const handleFilterBySort = (isAscending = false) => {
    if (selectedFilter.value.sort) {
      newReleases.value = newReleases.value?.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (isAscending) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      })
      currentWeekAssigned.value = currentWeekAssigned.value.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (isAscending) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      })

      lastWeekAssigned.value = lastWeekAssigned.value.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (isAscending) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      })

      overdueAssignments.value = overdueAssignments.value.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (isAscending) {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      })
    }
    else {
      handleBackToOriginal()
    }
  }

  const handleFilterByProgress = (filter) => {
    if (filter && ['not started', 'in progress', 'completed'].includes(filter)) {
      newReleases.value = newReleases.value?.filter((newRelease) => {
        switch (filter) {
          case "not started":
            return newRelease.status.toLowerCase() === 'not started';

          case "in progress":
            return newRelease.status.toLowerCase() === 'in progress';

          case "completed":
            return newRelease.status.toLowerCase() === 'completed';
          default:
            return true
        }
      })
      currentWeekAssigned.value = currentWeekAssigned.value.filter((assigned) => {
        switch (filter) {
          case "not started":
            return assigned.status.toLowerCase() === 'not started';

          case "in progress":
            return assigned.status.toLowerCase() === 'in progress';

          case "completed":
            return assigned.status.toLowerCase() === 'completed';
          default:
            return true
        }
      })

      lastWeekAssigned.value = lastWeekAssigned.value.filter((assigned) => {
        switch (filter) {
          case "not started":
            return assigned.status.toLowerCase() === 'not started';

          case "in progress":
            return assigned.status.toLowerCase() === 'in progress';

          case "completed":
            return assigned.status.toLowerCase() === 'completed';
          default:
            return true
        }
      })

      overdueAssignments.value = overdueAssignments.value.filter((assigned) => {
        switch (filter) {
          case "not started":
            return assigned.status.toLowerCase() === 'not started';

          case "in progress":
            return assigned.status.toLowerCase() === 'in progress';

          case "completed":
            return assigned.status.toLowerCase() === 'completed';
          default:
            return true
        }
      })
    }
    else {
      handleBackToOriginal()
    }
  }

  const handleBackToOriginal = () => {
    newReleases.value = originalNewRelease.value;
    currentWeekAssigned.value = originalCurrentWeekAssigned.value;
    lastWeekAssigned.value = originalLastWeekAssigned.value;
    overdueAssignments.value = originalOverdueAssignments.value;
  }

  const handleFilterByContentType = () => {
    if (selectedContentType.value) {
      newReleases.value = originalNewRelease.value?.filter((newRelease) => newRelease.type === selectedContentType.value);
      currentWeekAssigned.value = originalCurrentWeekAssigned.value.filter((assigned) => assigned.type === selectedContentType.value);
      lastWeekAssigned.value = originalLastWeekAssigned.value.filter((assigned) => assigned.type === selectedContentType.value);
      overdueAssignments.value = originalOverdueAssignments.value.filter((assigned) => assigned.type === selectedContentType.value);
      return;
    }
    handleBackToOriginal()
    handleFilterBySort(selectedFilter.value.sort === "a-z");
    handleFilterByProgress(selectedFilter.value.filter)
  }

  watch(selectedFilter, (value) => {
    handleFilterBySort(value.sort === "a-z");
    handleFilterByProgress(value.filter)
  })

  watch(selectedContentType, () => {
    handleFilterByContentType()
  })

  return {
    selectedFilter,
    selectedTimeline,
    selectedContentType,
    handleReset,
    handleFilterByProgress,
    handleFilterBySort,
    handleBackToOriginal,
    handleFilterByContentType,
  }
})
