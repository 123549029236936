<script setup>
import { storeToRefs } from 'pinia';
import { useLayout2HomeFiltersStore } from '../stores/useLayout2HomeFilters.store';
import LastWeekAssignmentList from './assignments/LastWeekAssignmentList.vue';
import OverdueAssignmentList from './assignments/OverdueAssignmentList.vue';
import ThisWeekAssignmentList from './assignments/ThisWeekAssignmentList.vue';
import NewReleaseList from './new-release/NewReleaseList.vue';

const layout2HomeFiltersStore = useLayout2HomeFiltersStore();
const { selectedTimeline } = storeToRefs(layout2HomeFiltersStore);
</script>
<template>
  <div class="bg-white p-2 sm:p-4 lg:p-8 rounded sm:rounded-sm md:rounded-md lg:rounded-lg flex flex-col gap-2 sm:gap-3 md:gap-4 lg:gap-5 flex-1 w-auto">
    <div class="flex flex-1 flex-col gap-2 sm:gap-3 md:gap-4 lg:gap-5 w-auto">
      <NewReleaseList v-if="!selectedTimeline || selectedTimeline === 'new_releases'" />
      <ThisWeekAssignmentList v-if="!selectedTimeline || selectedTimeline === 'current_week'" />
      <LastWeekAssignmentList v-if="!selectedTimeline || selectedTimeline === 'last_week'" />
      <OverdueAssignmentList v-if="!selectedTimeline || selectedTimeline === 'overdue'" />
    </div>
  </div>
</template>

<style lang="css" scoped></style>
