<template>
  <DeferredContent @onLoadComponent="getGroups">
    <div
      class="flex flex-col min-w-72 break-words w-full mb-6 shadow-lg rounded z-0"
    >
      <div class="px-4 py-5 flex-auto">
        <div class="tab-content tab-space">
          <div class="flex justify-between">
            <h4 class="font-medium leading-6 text-gray-900 inline">
              Your Groups
            </h4>
            <button
              type="button"
              @click="handleClickCreate"
              id="Group-List-Side-Create-button"
              class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-untitled-gray-400 hover:text-untitled-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 ml-2"
              v-has-restrict-learner
            >
              <PlusIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              Create New
            </button>
          </div>
          <div class="w-full min-h-96">
            <SkeletonGroupList v-if="loading" />
            <div v-else-if="!loading && groups.length > 0">
              <div class="flow-root mt-1">
                <ul role="list" class="my-1">
                  <li
                    v-for="group in groups"
                    :key="group.id"
                    class="p-5 mt-3 rounded shadow-md hover:shadow-lg shadow-untitled-gray-200 hover:shadow-untitled-gray-200"
                  >
                    <div
                      class="flex flex-col xl:flex-row lg:flex-row md:flex-row justify-between"
                    >
                      <div
                        class="cursor-pointer w-4/5"
                        @click="handleClickVisit(group)"
                      >
                        <ElTooltip
                          class="box-item"
                          effect="dark"
                          raw-content
                          :content="wrapTooltipContent(mutateName(group))"
                          placement="top"
                        >
                          <p
                            class="text-base font-medium text-untitled-gray-900 line-clamp-2"
                          >
                            {{ mutateName(group) }}
                          </p>
                        </ElTooltip>

                        <p class="text-xs text-untitled-gray-500 truncate">
                          {{ group.users_count }}
                          {{ group.users_count > 1 ? "Members" : "Member" }}
                        </p>
                      </div>
                      <GroupDropdownAction
                        :group="group"
                        @edit="handleClickEdit(group)"
                        @delete="handleClickDelete(group)"
                        @add="handleClickAddMembers(group)"
                        @view="handleClickViewMembers(group)"
                        @assignment="handleClickViewGroupAssignment(group)"
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mt-6">
                <router-link
                  to="/groups"
                  id="Group-List-Side-View-All-button"
                  class="w-full flex justify-center items-center px-4 py-2 border border-untitled-gray-300 shadow-sm text-sm font-medium rounded-md text-untitled-gray-700 bg-white hover:bg-untitled-gray-50"
                >
                  View all
                </router-link>
              </div>
            </div>
            <div v-else>
              <div class="block p-5">No groups found.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DeferredContent>

  <GroupFormModal :eventId="eventId" @save="handleClickSave" />
  <GroupAddMembersModal @save="handleClickSave" />

  <ConfirmDialog
    :dialogTitle="'Remove Confirmation'"
    :confirmButtonLabel="'Yes'"
    :cancelButtonLabel="'No'"
    :okButton="handleDeleteGroup"
    :cancelButton="handleClickCancel"
    :loading="confirmLoading"
    :open="confirmShow"
    >{{ confirmMessage }}
  </ConfirmDialog>

  <notification-dialog
    v-if="showNotification"
    :content="showNotificationMessage"
    :close="closeNotification"
  />

  <GroupMemberListModal :eventId="eventId" />
</template>

<script setup>
import { ref, inject } from "vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import NotificationDialog from "@/components/partials/NotificationDialog.vue";
import Bugsnag from "@bugsnag/js";
import EventBus from "@/plugins/eventBus";
import SkeletonGroupList from "@/components/loaders/SkeletonGroupList.vue";
import { PlusIcon } from "@heroicons/vue/solid";
import GroupDropdownAction from "@/components/groups/GroupDropdownAction";
import GroupFormModal from "@/components/groups/GroupFormModal";
import GroupAddMembersModal from "@/components/groups/GroupAddMembersModal.vue";
import { useRoute, useRouter } from "vue-router";
import GroupMemberListModal from "@/components/groups/GroupMemberListModal";
import { ElTooltip } from "element-plus";
import { useAuthStore } from "@/stores";
import { wrapTooltipContent } from "@/composable/wrapTooltipContent";
import DeferredContent from "../utilities/DeferredContent.vue";

const eventId = ref("groupListSide");

const route = useRoute();
const router = useRouter();
const authUser = useAuthStore();

const confirmShow = ref(false);
const confirmLoading = ref(false);
const loading = ref(false);
const confirmMessage = ref(
  "Deleting the group is irreversible, the group will be deleted but the content assigned to the group members will not be removed. Do you wish to proceed?"
);
const showNotification = ref(false);
const showNotificationMessage = ref("");
const selectedGroup = ref(null);

const groups = ref([]);

const axios = inject("axios");
const isAdmin = authUser.user?.roles?.find(
  (role) => role?.name === "super-administrator"
);
const getGroups = async () => {
  loading.value = true;
  await axios
    .get("/api/v3/groups")
    .then((response) => {
      groups.value = response.data.data;
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });

  loading.value = false;
};

const handleClickDelete = (group) => {
  confirmShow.value = true;
  selectedGroup.value = group;
};

const handleClickCancel = () => {
  confirmShow.value = false;
  selectedGroup.value = null;
};

const handleDeleteGroup = async () => {
  if (!selectedGroup.value) {
    return;
  }

  confirmLoading.value = true;

  await axios
    .delete(`/api/v3/groups/${selectedGroup.value.id}`)
    .then(() => {
      getGroups();

      notification("Group deleted successfully");
      confirmLoading.value = false;
      confirmShow.value = false;

      if (route.params.id == selectedGroup.value.id) {
        EventBus.$emit("deleteGroup", selectedGroup.value.id);
      }
    })
    .catch((error) => {
      Bugsnag.notify(error);
    });
};

const notification = (message) => {
  showNotificationMessage.value = message;
  showNotification.value = true;

  setTimeout(() => {
    closeNotification();
  }, 3000);
};

const closeNotification = () => {
  showNotification.value = false;
};

const handleClickCreate = () => {
  EventBus.$emit(`openGroupFormModal${eventId.value}`);
};

const handleClickSave = async (message) => {
  await getGroups();
  notification(message);
};

const handleClickEdit = (group) => {
  EventBus.$emit(`openGroupFormModal${eventId.value}`, group);
};

const handleClickAddMembers = (group) => {
  EventBus.$emit("openGroupAddMembersModal", group);
};

EventBus.$on("triggerDeleteConfirmation", (group) => {
  handleClickDelete(group);
});

const handleClickViewMembers = (group) => {
  EventBus.$emit(`openGroupMemberListModal${eventId.value}`, group);
};

const handleClickViewGroupAssignment = (group) => {
  router.push({
    name: "group-assignment",
    params: {
      id: group.id,
    },
  });
};

const handleClickVisit = (group) => {
  router.push({
    name: "group",
    params: {
      id: group.id,
    },
  });
};

const mutateName = (group) => {
  if (
    group.auto_add_all &&
    group.name.toLocaleLowerCase() === "all users" &&
    isAdmin
  ) {
    return `All Users - ${group.company.name}`;
  }

  return group.name;
};
</script>
