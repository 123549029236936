<script setup>
import { storeToRefs } from 'pinia';
import { useLayout2NewReleasesStore } from '../../stores/useLayout2NewReleases.store';
import Layout2ContentsList from '../contents/Layout2ContentsList.vue';
import DeferredContent from '@/components/utilities/DeferredContent.vue';
import { useLayout2HomeFiltersStore } from '../../stores/useLayout2HomeFilters.store';

const layout2FiltersStore = useLayout2HomeFiltersStore()
const newReleasesStore = useLayout2NewReleasesStore()
const { newReleases, loadingNewReleases}  = storeToRefs(newReleasesStore)
const  { selectedFilter } = storeToRefs(layout2FiltersStore)

const handleFetchNewReleases = async () => {
  await newReleasesStore.handleFetchNewReleases()
  layout2FiltersStore.handleFilterBySort(selectedFilter.value.sort === 'a-z')
  layout2FiltersStore.handleFilterByProgress(selectedFilter.value.filter)
  layout2FiltersStore.handleFilterByContentType()
}
</script>
<template>
  <DeferredContent @onLoadComponent="handleFetchNewReleases">
    <Layout2ContentsList
      title="New Releases"
      :contents="newReleases"
      :loading="loadingNewReleases"
      emptyText="No New Releases Available"
    />
  </DeferredContent>
</template>

<style lang="scss" scoped></style>
