<template>
  <el-dialog
    v-model="weeklyReportStore.isDialogVisible"
    :show-close="false"
    center
    width="30%"
    :before-close="handleClose"
  >
    <template #header>
      <div class="flex justify-between items-center">
        <h1>Weekly Report Settings</h1>
        <XIcon
          class="h-5 w-5 hover:cursor-pointer"
          @click="weeklyReportStore.hideDialog()"
        ></XIcon>
      </div>
    </template>
    <div class="flex flex-col gap-3 px-6 py-6">
      <div>
        <e-combobox
          v-model="selectedTimeZone"
          :options="timezones"
          option-name="timezone"
          value-key="timezone"
          placeholder="Timezone"
          :multiple="false"
          :filterable="true"
          :clearable="true"
          :disabled="!subscribeTeamWeeklyReport && !subscribeMeWeeklyReport"
          :loading="timezoneLoading"
          :key="selectedTimeZone"
        />
      </div>

      <div class="flex gap-1">
        <e-combobox
          v-model="selectedDay"
          :options="days"
          option-name="name"
          value-key="id"
          placeholder="Day"
          :multiple="false"
          :filterable="true"
          :clearable="true"
          :disabled="!subscribeTeamWeeklyReport && !subscribeMeWeeklyReport"
          :key="selectedDay"
        />
        <e-combobox
          v-model="selectedTime"
          :options="timeOptions"
          placeholder="Time"
          option-name="name"
          value-key="value"
          :multiple="false"
          :filterable="true"
          :clearable="true"
          :disabled="!subscribeTeamWeeklyReport && !subscribeMeWeeklyReport"
          :key="selectedTime"
        />
      </div>

      <div
        v-if="isSuperAdmin || isAccountManager || isStoreManager"
        class="flex items-center gap-2"
      >
        <el-checkbox
          v-model="subscribeTeamWeeklyReport"
          label="Subscribe my team from weekly report"
        ></el-checkbox>
      </div>

      <div class="flex items-center gap-2">
        <el-checkbox
          v-model="subscribeMeWeeklyReport"
          label="Subscribe me from weekly report"
        ></el-checkbox>
      </div>

      <div v-if="isSuperAdmin || isAccountManager || isStoreManager">
        <button
          :disabled="disabledEditReceiver || disabledAllButton"
          @click="innerVisible = true"
        >
          Edit Receiver
        </button>
      </div>

      <div class="flex gap-2 justify-end w-full">
        <v-button
          :disabled="disabledAllButton"
          @click="updateUserWeeklySettings"
          >Save Changes</v-button
        >
      </div>

      <el-dialog
        v-model="innerVisible"
        width="30%"
        height="500px"
        title="Weekly Report Receivers"
        append-to-body
      >
        <div class="flex flex-col gap-3 px-6 py-6">
          <v-text-field
            v-model="searchString"
            placeholder="Search users..."
            @keypress="searchUsers"
          />

          <div class="mt-2 max-h-[300px] overflow-y-auto p-2">
            <ul>
              <li
                v-for="(user, index) in userOptions"
                :key="user.id"
                class="flex justify-between p-2"
                :class="`${
                  index % 2 == 0
                    ? 'bg-untitled-gray-200'
                    : 'bg-untitled-gray-25'
                }`"
                @click="user = selecteUser(user.id)"
              >
                <span>{{ user?.name }}</span>
                <span>
                  <CheckIcon
                    v-if="user.subscribe"
                    class="h-5 w-5 text-green-700"
                  />
                </span>
              </li>
            </ul>
          </div>
          <template v-if="totalSelectedItem < userReceivers.length">
            <button class="mt-2" @click="selectAll">Select All</button>
          </template>
          <template v-else>
            <button class="mt-2" @click="deselectAll">Deselect All</button>
          </template>

          <div class="w-full flex justify-end">
            <v-button
              :disabled="disabledAllButton"
              @click="updateWeeklyReceivers"
              :loading="loading"
              >Save</v-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, computed, inject, watch, onMounted } from "vue";
import { ElDialog, ElCheckbox } from "element-plus";
import dayjs from "dayjs";
import { VButton, VTextField } from "revdojo-vue-components";
import ECombobox from "@/components/element-components/ECombobox";
import { XIcon, CheckIcon } from "@heroicons/vue/solid";
import eventBus from "@/plugins/eventBus";
import Bugsnag from "@bugsnag/js";
import { useAuthStore } from "@/stores";
import {
  debounce as _debounce,
  filter as _filter,
  isNil as _isNil,
} from "lodash-es";
import { useWeeklyReportStore } from "@/stores/useWeeklyReportSettings";
import { storeToRefs } from "pinia";
const weeklyReportStore = useWeeklyReportStore();
const { isDialogVisible } = storeToRefs(weeklyReportStore);
const authUser = useAuthStore();

const axios = inject("axios");
const innerVisible = ref(false);
const timezoneLoading = ref(false);
const selectedTimeZone = ref(null);
const selectedDay = ref(null);
const selectedTime = ref(null);
const timezones = ref([]);

const subscribeTeamWeeklyReport = ref(false);
const subscribeMeWeeklyReport = ref(false);
const loading = ref(false);

const searchString = ref("");

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const userReceivers = ref([]);

const searchUsersItems = ref([]);

const getTimezones = () => {
  timezoneLoading.value = true;

  axios
    .get("/api/v3/timezones", {
      params: {
        timezones: [
          "America/Chicago",
          "America/Denver",
          "America/Phoenix",
          "America/Los_Angeles",
          "America/Anchorage",
          "Pacific/Honolulu",
        ],
      },
    })
    .then(({ data }) => {
      timezones.value = data;
      timezoneLoading.value = false;
    })
    .catch(() => {
      timezoneLoading.value = false;
    });
};

const getUsers = () => {
  axios.get("/api/v3/weekly-report/receivers").then(({ data }) => {
    userReceivers.value = data.map((user) => {
      return {
        ...user,
      };
    });
  });
};

const selectAll = () => {
  userReceivers.value = userReceivers.value?.map((user) => {
    return {
      ...user,
      subscribe: 1,
    };
  });
};

const deselectAll = () => {
  userReceivers.value = userReceivers.value?.map((user) => {
    return {
      ...user,
      subscribe: 0,
    };
  });
};

const selecteUser = (id) => {
  const user = userReceivers?.value?.find((user) => user?.id == id);

  user.subscribe = Number(!user.subscribe);
};

const updateWeeklySettings = async (params, successMessage) => {
  try {
    loading.value = true;

    await axios.post("/api/v3/weekly-report/update", params);

    eventBus.$emit("NOTIFICATION_DIALOG", {
      show: true,
      message: successMessage,
    });

    loading.value = false;

    return 200;
  } catch (error) {
    Bugsnag.notify(error);
    eventBus.$emit("NOTIFICATION_DIALOG", {
      show: true,
      message: error?.message,
    });

    loading.value = false;

    return 400;
  }
};

const getWeeklySettings = async () => {
  axios.get("/api/v3/weekly-report/settings").then(({ data }) => {
    if (data === null) {
      eventBus.$emit("NOTIFICATION_DIALOG", {
        show: true,
        message: "Failed to load the weekly report settings",
      });

      return;
    }

    const teamSubscribe = data?.dealer?.customable?.subscribe
      ? data?.dealer?.customable?.subscribe
      : data?.company?.customable?.subscribe;

    subscribeMeWeeklyReport.value = data?.customable?.subscribe == 1;
    subscribeTeamWeeklyReport.value = teamSubscribe == 1;
    selectedDay.value = data?.customable?.day;
    selectedTimeZone.value = data?.customable?.timezone;
    selectedTime.value = dayjs(data?.customable?.time, 'HH:mm:ss').format('h:mm A');
  });
};

const updateUserWeeklySettings = async () => {
  weeklyReportStore.hideDialog();
  const params = {
    unsubscribe_team: !subscribeTeamWeeklyReport.value,
    unsubscribe_me: !subscribeMeWeeklyReport.value,
    timezone: selectedTimeZone.value,
    day: selectedDay.value,
    time: convertTime(selectedTime.value),
  };

  await updateWeeklySettings(params, "Successfully update the weekly settings");
};

const convertTime = (ampm) =>
  ampm ? dayjs(`1/1/1 ${ampm}`).format("HH:mm:00") : null;

const updateWeeklyReceivers = async () => {
  const params = {
    users: userReceivers.value,
    timezone: selectedTimeZone.value,
    day: selectedDay.value,
    time: convertTime(selectedTime.value),
  };

  await updateWeeklySettings(
    params,
    "Successfully update the weekly receivers"
  );
};

const searchUsers = _debounce(async () => {
  const items = await _filter(userReceivers.value, (item) => {
    return item?.name
      ?.toLowerCase()
      ?.includes(searchString.value?.toLowerCase());
  });

  searchUsersItems.value = items;
}, 100);

const disabledEditReceiver = computed(() => {
  return (
    !subscribeTeamWeeklyReport?.value &&
    selectedTime?.value &&
    selectedDay?.value &&
    selectedTimeZone?.value
  );
});

const timeOptions = computed(() => {
  const now = new Date();
  const startTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    0,
    0,
    0,
    0
  );
  const time = [];
  while (startTime.getDate() === now.getDate()) {
    time.push({
      name: startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      value: startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
    });
    startTime.setTime(startTime.getTime() + 15 * 60 * 1000);
  }
  return time;
});

const handleClose = () => {
  weeklyReportStore.hideDialog();
};

const disabledAllButton = computed(() => {
  return (
    _isNil(selectedTimeZone.value) ||
    _isNil(selectedDay.value) ||
    _isNil(selectedTime.value)
  );
});

const totalSelectedItem = computed(() => {
  const items = userReceivers.value.filter((item) => item?.subscribe);

  return items.length;
});

const currentUser = computed(() => {
  return authUser.$state.user;
});

const isSuperAdmin = computed(() => {
  if (!currentUser.value) {
    return false;
  }

  return currentUser.value.roles.some(
    (role) => role.name === "super-administrator"
  );
});

const isAccountManager = computed(() => {
  if (!currentUser.value) {
    return false;
  }

  return currentUser.value.roles.some(
    (role) => role.name === "account-manager"
  );
});

const isStoreManager = computed(() => {
  if (!currentUser.value) {
    return false;
  }

  return currentUser.value.roles.some(
    (role) => role.name === "specific-dealer-manager"
  );
});

const userOptions = computed(() => {
  if (searchString?.value?.length) {
    return searchUsersItems.value;
  }

  return userReceivers.value;
});

watch(isDialogVisible,  async () => {
  await getTimezones();
  await getUsers();
  await getWeeklySettings();
});

onMounted(async () => {
  await getTimezones();
  await getUsers();
  await getWeeklySettings();
});
</script>
