import { defineStore } from "pinia";
import { ref } from "vue";

export const useLayout2HomeTabsStore = defineStore(
  "layout2HomeTabsStore",
  () => {
    const selectedRightSectionTab = ref("report_summary");
    const selectedMainTab = ref("enrolled");
    const listType = ref("grid");

    return {
      listType,
      selectedMainTab,
      selectedRightSectionTab,
    };
  }
);
