import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { AxiosError } from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useLayout2NewReleasesStore = defineStore("layout2NewReleasesStore", () => {
  const newReleases = ref([]);
  const loadingNewReleases = ref(false);
  const originalNewRelease = ref([]);

  const handleFetchNewReleases = async () => {
    try {
      loadingNewReleases.value = true;
      const { data } = await http().get("/v3/new-releases");
      newReleases.value = data;
      originalNewRelease.value = [...data];
    } catch (error) {
      if (error instanceof AxiosError) {
        useHandleErrorStatus(error);
      }
    } finally {
      loadingNewReleases.value = false;
    }
  }

  return {
    newReleases,
    loadingNewReleases,
    originalNewRelease,
    handleFetchNewReleases,
  }
})
