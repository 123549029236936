<template>
  <el-menu
    class="h-10 w-fit"
    :default-active="activeIndex"
    mode="horizontal"
    :ellipsis="false"
    :close-on-click-outside="true"
    style="
      height: 40px;
      background-color: #f2f4f7;
      border-radius: 8px;
      border: none;
    "
  >
    <el-sub-menu index="">
      <template #title>
        <div class="flex gap-1 justify-center items-center">
          <AcademicCapIcon class="w-5 text-untitled-gray-600" />
          <span class="text-untitled-gray-600 text-sm font-semibold"
            >{{ title }}</span
          >
        </div>
      </template>
      <template #default v-if="!loadingCategories">
        <template v-for="(category, index) in categories" :key="index">
          <el-menu-item v-if="!category.courses">{{
            category.name
          }}</el-menu-item>
          <el-sub-menu :index="`${index}`" v-else>
            <template #title>{{ category.name }}</template>
            <div class="px-2 mb-2" v-if="category.courses.length > 2">
              <el-input v-model="search" placeholder="Search course"></el-input>
            </div>
            <div
              v-if="filteredCourse(category).length > 0"
              class="max-h-[250px] w-[300px] overflow-y-auto overflow-x-hidden"
            >
              <el-menu-item
                style="height: auto !important; text-wrap: balance !important"
                v-for="(course, courseIndex) in filteredCourse(category)"
                @click="handleClickCourse(course)"
                :index="`${index}-${courseIndex}`"
                :key="courseIndex"
              >
                {{ course.name }}
              </el-menu-item>
            </div>
            <el-menu-item
              v-else
              style="height: auto !important; text-wrap: balance !important"
              disabled
            >
              No Courses Found
            </el-menu-item>
          </el-sub-menu>
        </template>
      </template>
      <template #default v-else>
        <div class="flex flex-col gap-2 bg-white px-2 py-4">
          <div
            v-for="n in 10"
            :key="n"
            class="w-full p-2 rounded-md bg-untitled-gray-300 animate-pulse"
          ></div>
        </div>
      </template>
    </el-sub-menu>
  </el-menu>
  <ViewCourse
    v-model:showEnrollCourse="showSelectedCourseFromMegaMenu"
    :course="courseProfile"
    :hide-button="true"
    :course-id="courseId"
    @onEnrolledCourse="fetchCourses"
  />
</template>

<script setup>
import { useAllAvailableCourseStore } from "@/stores/useAllAvailableCourse.store";
import { AcademicCapIcon } from "@heroicons/vue/solid";
import { storeToRefs } from "pinia";
import { ElMenu, ElMenuItem, ElSubMenu, ElInput } from "element-plus";
import { ref, inject, computed, defineAsyncComponent, defineProps } from "vue";
import { getRoles } from "@/composable/users/getRoles";
import { useRouter } from "vue-router";
import { useHomeCoursesStore } from "@/stores/home/useHomeCourses.store";

const props = defineProps({
  title: {
    type: String,
    default: "Filter By Category",
    required: false,
  }
})

const ViewCourse = defineAsyncComponent(() =>
  import("../utilities/ViewCourse.vue")
);

const homeCoursesStore = useHomeCoursesStore();
const allAvailableCourseStore = useAllAvailableCourseStore();
const { categories, loadingCategories } = storeToRefs(allAvailableCourseStore);

const courseSectionProvide = inject("fetchCourses");
const router = useRouter();
const roles = getRoles();
const activeIndex = ref("0");
const search = ref("");

const courseProfile = ref(null);
const showSelectedCourseFromMegaMenu = ref(false);
const courseId = ref(null);

const title = computed(() => props.title)
const isManager = computed(() => {
  return (
    roles.isSuperAdmin.value ||
    roles.isAccountManager.value ||
    roles.isSpecificManager.value
  );
});

const filteredCourse = (category) => {
  if (!search.value) {
    return category.courses;
  }

  return category.courses.filter((course) =>
    course.name.toLowerCase().includes(search.value.toLowerCase())
  );
};

const handleClickCourse = (course) => {
  if (course.type === "scorm") {
    router.push({
      name: "ScormCourseWatch",
      params: { id: course.scorm_provider_id },
    });
    return;
  }

  if (course.is_enrolled || isManager.value) {
    const id = course.unit_display
      ? course.unit_display.id
      : course.first_unit.id;

    router.push({ name: "units", params: { id: id } });
    return;
  }

  courseId.value = course.id;
  courseProfile.value = course;
  showSelectedCourseFromMegaMenu.value = true;
  if (courseSectionProvide) {
    courseSectionProvide.openViewCourse(course);
  }
};

const fetchCourses = () => {
  homeCoursesStore.handleFetchCourses();
};
</script>

<style scoped></style>
