import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { AxiosError } from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useLayout2WeeklyAssignedContentsStore = defineStore("layout2WeeklyAssignedContentsStore", () => {

  const currentWeekAssigned = ref([]);
  const lastWeekAssigned = ref([]);
  const originalCurrentWeekAssigned = ref([]);
  const originalLastWeekAssigned = ref([]);
  const loadingWeeklyAssignedContentsCurrentWeek = ref(false);
  const loadingWeeklyAssignedContentsPreviousWeek = ref(false);

  const handleFetchCurrentWeekAssignedContents = async () => {
    try {
      loadingWeeklyAssignedContentsCurrentWeek.value = true;
      const { data } = await http().get("/v3/weekly-assigned-contents", {
        params: {
          previous_week: 0,
        }
      });
      currentWeekAssigned.value = data.data;
      originalCurrentWeekAssigned.value = [...data.data]
    } catch (error) {
      if (error instanceof AxiosError) {
        useHandleErrorStatus(error);
      }
    } finally {
      loadingWeeklyAssignedContentsCurrentWeek.value = false;
    }
  }

  const handleFetchLastWeekAssignedContents = async () => {
    try {
      loadingWeeklyAssignedContentsPreviousWeek.value = true;
      const { data } = await http().get("/v3/weekly-assigned-contents", {
        params: {
          previous_week: 1,
        }
      });
      lastWeekAssigned.value = data.data;
      originalLastWeekAssigned.value = [...data.data]
    } catch (error) {
      if (error instanceof AxiosError) {
        useHandleErrorStatus(error);
      }
    } finally {
      loadingWeeklyAssignedContentsPreviousWeek.value = false;
    }
  }

  return {
    currentWeekAssigned,
    lastWeekAssigned,
    loadingWeeklyAssignedContentsCurrentWeek,
    loadingWeeklyAssignedContentsPreviousWeek,
    originalLastWeekAssigned,
    originalCurrentWeekAssigned,
    handleFetchCurrentWeekAssignedContents,
    handleFetchLastWeekAssignedContents,
  }
})
