<script setup>
import CourseFilters from "@/components/home/filters/CourseFilters.vue";
import { RefreshIcon, SearchIcon } from "@heroicons/vue/solid";
import { ElButton, ElInput } from "element-plus";
import DropdownMenu from "@/components/element-components/DropdownMenu.vue";
import { storeToRefs } from "pinia";
import { defineProps, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useLayout2HomeFiltersStore } from "../../stores/useLayout2HomeFilters.store";
import { useLayout2NewReleasesStore } from "../../stores/useLayout2NewReleases.store";
import { useLayout2OverdueAssignmentsStore } from "../../stores/useLayout2OverdueAssignments.store";
import { useLayout2WeeklyAssignedContentsStore } from "../../stores/useLayout2WeeklyAssignedContents.store";
import FilterByContentType from "../utilities/FilterByContentType.vue";

const props = defineProps({
  hideSortProgress: {
    type: Boolean,
    default: false,
  },
  hideEnrolledProgress: {
    type: Boolean,
    default: false,
  },
  hideAvailableCourse: {
    type: Boolean,
    default: false,
  },
  courseFilters: {
    type: Boolean,
  },
  showHighLowProgress: {
    type: Boolean,
    default: true,
  },
});

const router = useRouter();
const layout2HomeFiltersStore = useLayout2HomeFiltersStore();
const layoutNewReleaseStore = useLayout2NewReleasesStore();
const layout2OverdueAssignmentStore = useLayout2OverdueAssignmentsStore();
const layout2AssignmentsStore = useLayout2WeeklyAssignedContentsStore();

const { selectedFilter, selectedContentType, selectedTimeline } = storeToRefs(
  layout2HomeFiltersStore
);

const search = ref("");
const refreshKey = ref(0)

const hideSortProgress = computed(() => props.hideSortProgress);
const hideEnrolledProgress = computed(() => props.hideEnrolledProgress);
const hideAvailableCourse = computed(() => props.hideAvailableCourse);

const handleOnSearch = () => {
  router.push({
    name: "search",
    query: {
      search: search.value,
    },
  });
};

const handleClickReset = () => {
  layout2HomeFiltersStore.handleReset();
  refreshKey.value++
  handleClearFilters()
};

const handleApplyFilters = () => {
  if (!selectedFilter.value.sort) {
    layout2HomeFiltersStore.handleBackToOriginal();
    layout2HomeFiltersStore.handleFilterByProgress(selectedFilter.value.sort)
  }
  if (!selectedFilter.value.filter) {
    layout2HomeFiltersStore.handleBackToOriginal();
    layout2HomeFiltersStore.handleFilterBySort(selectedFilter.value.sort === 'a-z')
  }
};

const handleClearFilters = () => {
  layoutNewReleaseStore.handleFetchNewReleases();
  layout2OverdueAssignmentStore.handleFetchOverdueAssignmentsContents();
  layout2AssignmentsStore.handleFetchCurrentWeekAssignedContents();
  layout2AssignmentsStore.handleFetchLastWeekAssignedContents();
};
</script>

<template>
  <div class="flex justify-between w-full gap-3 flex-col md:flex-row">
    <div class="flex flex-row flex-wrap gap-2 w-full">
      <div
        class="max-w-96 w-full min-w-40 grow flex justify-start items-center"
      >
        <ElInput
          :prefix-icon="SearchIcon"
          size="large"
          placeholder="Please type to search.."
          v-model="search"
          @keyup.enter="handleOnSearch"
        />
      </div>
      <div class="flex justify-start items-center w-fit">
        <CourseFilters
          v-model="selectedFilter"
          :hideSortProgress="hideSortProgress"
          :hideEnrolledProgress="hideEnrolledProgress"
          :hideAvailableCourse="hideAvailableCourse"
          :show-category="false"
          :showHighLowProgress="false"
          custom
          @applyFilters="handleApplyFilters"
          @clearFilters="handleClearFilters"
          :key="refreshKey"
        />
      </div>
      <div class="flex justify-start items-center w-fit">
        <ElButton @click="handleClickReset" :icon="RefreshIcon" size="large">
          Reset
        </ElButton>
      </div>
    </div>

    <div>
      <DropdownMenu title="Filter by Content Type" v-if="false" />
      <FilterByContentType
        v-model:selected-timeline="selectedTimeline"
        v-model:selectedFilterByContentType="selectedContentType"
      />
    </div>
  </div>
</template>

<style lang="css"></style>
