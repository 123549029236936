<script setup>
import { defineProps, computed, defineEmits, onMounted } from "vue";
import ECombobox from "../element-components/ECombobox.vue";
import { useAllAvailableCourseStore } from "@/stores/useAllAvailableCourse.store";
import { storeToRefs } from "pinia";

const allAvailableCourseStore = useAllAvailableCourseStore();
const { categoriesWithCourse, loadingCategories } = storeToRefs(
  allAvailableCourseStore
);

const props = defineProps({
  modelValue: null,
  disabled: {
    type: Boolean,
    default: false,
  },
  teleported: {
    type: Boolean,
    default: true,
  },
  reloadPerSelect: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "change"]);
const selectedCategories = computed({
  get() {
    return props.modelValue ?? [];
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});

const options = computed(() => {
  return categoriesWithCourse.value;
});

const handleChange = (value) => {
  emit("change", value);
};

onMounted(() => {
  allAvailableCourseStore.handleFetchCategoriesNoCourses();
});
</script>
<template>
  <div v-bind="$attrs" class="w-full">
    <ECombobox
      v-model="selectedCategories"
      :multiple="true"
      :filterable="true"
      value-key="id"
      option-name="name"
      placeholder="Select category"
      :allow-select-all="true"
      :loading="loadingCategories"
      :options="options"
      @change="handleChange"
      :teleported="teleported"
    />
  </div>
</template>
