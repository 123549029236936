import { defineStore } from "pinia";
import { ref } from "vue";

export const useLayout2FilterByContentTypeStore = defineStore("layout2FilterByContentTypeStore", () => {

  const timeline = ref([
    {
      label: 'New Releases',
      value: 'new_releases',
    },
    {
      label: `This Week's Assignments`,
      value: 'current_week',
    },
    {
      label: `Last Week's Assignments`,
      value: 'last_week',
    },
    {
      label: 'Overdue Assignments',
      value: 'overdue',
    },
  ])
  const selectedTimeline = ref("")
  const selectedContentType = ref("");
  const contentTypes = ref([
    {
      value: 'unit',
      label: 'Unit',
    },
    {
      value: 'module',
      label: 'Module',
    },
    {
      value: 'course',
      label: 'Course',
    },
    {
      value: 'playlist',
      label: 'Learning Path & Playlist',
    }
  ])

  return {
    timeline,
    selectedTimeline,
    selectedContentType,
    contentTypes,
  }
})
