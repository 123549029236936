<script setup>
import AssignDialogGlobal from "@/components/utilities/AssignDialogGlobal.vue";
import { wrapTooltipContent } from "@/composable/wrapTooltipContent";
import { dateFormat } from "@/helpers/date_formatter";
import {
  AcademicCapIcon,
  DotsVerticalIcon,
  PencilAltIcon,
  PlayIcon,
  TagIcon,
} from "@heroicons/vue/solid";
import {
  ElButton,
  ElDropdown,
  ElDropdownItem,
  ElDropdownMenu,
  ElProgress,
  ElTooltip,
} from "element-plus";
import { capitalize } from "lodash";
import { computed, defineProps, ref } from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  content: {
    type: Object,
    required: true,
    default: null,
  },
});

const router = useRouter();

const showAssignDialog = ref(false);
const selectedUnitId = ref(null);
const selectedCourseId = ref(null);
const selectedModuleId = ref(null);
const selectedPlaylistId = ref(null);

const content = computed(() => props.content);
const playLabel = computed(() => {
  if (content.value.progress <= 0) {
    return "Start Learning";
  }
  if (content.value.progress > 0 && content.value.progress < 100) {
    return "Continue Learning";
  }

  return "Finish Learning";
});

const handleClickContent = () => {
  if (content.value.type === "playlist") {
    router.push({
      name: "units",
      params: { id: content.value.first_unit_id, playlistId: content.value.id },
    });
    return;
  }
  router.push({ name: "units", params: { id: content.value.first_unit_id } });
};

const handleClickAssign = () => {
  initializeSelectedId();
  showAssignDialog.value = !showAssignDialog.value;
};

const initializeSelectedId = () => {
  selectedUnitId.value =
    content.value.type === "unit" ? content.value.type_id : null;
  selectedCourseId.value =
    content.value.type === "course" ? content.value.type_id : null;
  selectedModuleId.value =
    content.value.type === "module" ? content.value.type_id : null;
  selectedPlaylistId.value =
    content.value.type === "playlist" ? content.value.type_id : null;
};

const handleClickEditContent = () => {
  router.push(content.value.edit_url);
};
</script>
<template>
  <div
    class="w-80 min-w-80 h-72 shadow border-untitled-gray-200 flex flex-col rounded-md relative"
  >
    <div
      class="w-full flex h-48 relative cursor-pointer"
      @click="handleClickContent"
    >
      <img
        v-lazy="{
          src: content.thumbnail,
          error:
            'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
          loading:
            'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
        }"
        class="w-full object-cover object-center rounded-t-md"
      />
      <div
        class="flex flex-col gap-1 w-11/12 bottom-2 inset-x-0 mx-auto absolute"
        v-if="content.progress"
      >
        <span class="text-xs text-white">{{ content.progress }}%</span>
        <el-progress
          style="width: 100%"
          :color="'#1989fa'"
          text-inside
          striped
          striped-flow
          :percentage="content.progress"
          ><span
        /></el-progress>
      </div>
      <div
        class="flex flex-col gap-1 w-11/12 bottom-2 inset-x-0 mx-auto absolute text-xs"
        v-if="content.status"
      >
        <div
          class="w-fit px-2 py-0.5 rounded-lg font-semibold"
          :class="{
            'text-green-600 bg-green-100 backdrop-blur-md bg-opacity-50':
              content.status.toLowerCase() === 'completed',
            'text-yellow-600 bg-yellow-100 backdrop-blur-md bg-opacity-50':
              content.status.toLowerCase() === 'in progress',
            'text-blue-600 bg-blue-100 backdrop-blur-md bg-opacity-50':
              content.status.toLowerCase() === 'not started',
          }"
        >
          <span>{{ content.status }}</span>
        </div>
      </div>
      <div
        class="absolute inset-0 m-auto text-white flex justify-center items-center w-fit"
      >
        <PlayIcon class="opacity-50 w-20" />
      </div>
    </div>
    <div class="flex flex-col gap-1 w-full p-2 flex-1 bg-untitled-gray-50">
      <div class="flex justify-between text-xs text-untitled-gray-600">
        <div class="flex gap-1 justify-start items-center">
          <span class="font-semibold">Added on:</span>
          <span>{{ dateFormat(content.created_at, "MMM DD, YYYY") }}</span>
        </div>
        <div
          class="flex gap-1 justify-end items-center"
          v-if="content.due_date"
        >
          <span class="font-semibold">Due:</span>
          <span>{{ dateFormat(content.due_date, "MMM DD, YYYY") }}</span>
        </div>
      </div>
      <div class="flex justify-start items-start grow flex-1">
        <ElTooltip
          class="box-item"
          effect="dark"
          raw-content
          :content="wrapTooltipContent(content.name)"
        >
          <h1 class="line-clamp-2 text-untitled-gray-600 font-semibold text-sm">
            {{ content.name }}
          </h1>
        </ElTooltip>
      </div>
    </div>
    <div
      class="top-0 bg-blue-400 text-white text-xs rounded-br-md left-0 absolute px-4 rounded-tl-md py-0.5 font-semibold uppercase"
    >
      <p>{{ content.type }}</p>
    </div>
    <div class="top-2 right-2 absolute">
      <ElDropdown>
        <ElButton :icon="DotsVerticalIcon" size="small"></ElButton>
        <template #dropdown>
          <ElDropdownMenu>
            <el-dropdown-item
              :icon="TagIcon"
              @click="handleClickAssign"
              v-if="content.can_assigned_contents"
            >
              Assign {{ capitalize(content.type) }}
            </el-dropdown-item>
            <el-dropdown-item
              @click="handleClickEditContent"
              v-if="content.can_edit"
              :icon="PencilAltIcon"
              >Edit {{ capitalize(content.type) }}
            </el-dropdown-item>
            <el-dropdown-item
              :icon="AcademicCapIcon"
              @click="handleClickContent"
            >
              {{ playLabel }}
            </el-dropdown-item>
          </ElDropdownMenu>
        </template>
      </ElDropdown>
    </div>
    <AssignDialogGlobal
      v-model="showAssignDialog"
      :isUnit="content.type === 'unit'"
      :is-playlist="content.type === 'playlist'"
      :is-module="content.type === 'module'"
      :unit-id="selectedUnitId"
      :playlist-id="selectedPlaylistId"
      :module-id="selectedModuleId"
      :course-id="selectedCourseId"
      :title="`Assign ${content.name}`"
    />
  </div>
</template>

<style lang="scss" scoped></style>
